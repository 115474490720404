import { useCallback } from 'react';
import { useLocation } from 'wouter';

import { NamePaths as path } from 'shared/index';

export function useForm() {
    const [, pushLocation] = useLocation();
    const handleSubmit = useCallback(
        (e) => {
            e.preventDefault();
            pushLocation(path.home);
        },
        [pushLocation]
    );
    return {
        handleSubmit,
    };
}
