import React from 'react';
import { Link as WLink } from 'wouter';
import style from './index.module.css';

export default function Link({ clase, label, path, ...args }) {
    const addclass = style[clase] ? style[clase] : '';
    return (
        <WLink className={`${style['c-link']} ${addclass}`} href={path} {...args}>
            {label}
        </WLink>
    );
}
