import React, { lazy } from 'react';
import { Switch, Route } from 'wouter';

import { NamePaths as path } from 'shared/index';

const LoginPage = lazy(() => import('./login/index.js'));
const HomePage = lazy(() => import('./home/index.js'));
const ZonePage = lazy(() => import('./zone/index.js'));
const BuildPage = lazy(() => import('./building/index.js'));

export default function Routes() {
    return (
        <Switch>
            <Route component={LoginPage} path={path.login} />
            <Route component={HomePage} path={path.home} />
            <Route component={ZonePage} path={path.zone('/:id')} />
            <Route component={BuildPage} path={path.zone('/:zone') + path.building('/:id')} />
        </Switch>
    );
}
