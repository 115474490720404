import React, { Suspense } from 'react';

import Routes from 'pages/index.js';
import { Loading } from 'components/index';

import './App.css';

function App() {
    return (
        <Suspense fallback={<Loading />}>
            <Routes />
        </Suspense>
    );
}

export default App;
