import React from 'react';
import './index.css';
import { useForm } from './hook';

export default function FormLogin() {
    const { handleSubmit } = useForm();

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label htmlFor="emailUser" className="form-label">
                    Correo
                </label>
                <input type="email" className="form-control" id="emailUser" aria-describedby="emailHelp" />
            </div>
            <div className="mb-3">
                <label htmlFor="passwordUser" className="form-label">
                    Contraseña
                </label>
                <input type="password" className="form-control" id="passwordUser" />
            </div>
            <button type="submit" className="btn btn-primary">
                Ingresar
            </button>
        </form>
    );
}
